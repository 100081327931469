<template>
  <div
    class="custom-input"
    :class="{ 'custom-input--focused': focused, 'custom-input--error': error }"
  >
    <div class="input-content">
      <label v-if="value">{{ label }}</label>

      <div class="d-flex align-items-center justify-content-center">
        <slot name="left-side"></slot>

        <input
          v-if="$attrs.type === undefined || $attrs.type !== 'textarea'"
          :value="value"
          v-bind="$attrs"
          @focus="focused = true"
          @blur="focused = false"
          @input="$emit('input', $event.target.value)"
        />

        <textarea
          v-else
          :value="value"
          v-bind="$attrs"
          @focus="focused = true"
          @blur="focused = false"
          @input="$emit('input', $event.target.value)"
        ></textarea>

        <slot name="right-side" :error="error" :focused="focused"></slot>
        <strong v-if="error"><i class="tmlps-icn-alert h4 error-indicator"></i></strong>
      </div>
    </div>

    <div class="error-message" v-if="error">{{ error }}</div>
  </div>
</template>

<script>
export default {
  name: 'BaseInput',
  inheritAttrs: false,
  props: {
    label: { type: String, default: '' },
    value: { type: String },
    error: { type: String, default: null },
  },
  data() {
    return {
      focused: false,
    };
  },
};
</script>

<style lang="sass" scoped>
.custom-input
  position: relative
  display: block

  .input-content
    border: 1px solid #C1C1C1
    border-radius: 5px
    transition-property: border-color
    transition-duration: 0.2s
    transition-timing-function: linear
    overflow: hidden

  &--focused
    .input-content
      outline: none
      border: 1px solid var(--primary)
      box-shadow: none
    label
      color: var(--primary)

  &--error
    .input-content
      border: 1px solid red
    label
      color: red

  .error-message
    color: red
    font-size: 0.75rem
    padding-left: 2.5rem
    padding-right: 1rem

  .error-indicator
    color: red
    margin-left: 0.75rem
    margin-right: 0.75rem

  input, textarea
    flex-grow: 1
    color: var(--primary)
    font-size: 0.875rem
    box-shadow: none
    border: none
    outline: none
    padding-left: 0.75rem
    padding-top: 0.75rem
    padding-bottom: 0.75rem
    -webkit-text-fill-color: #242424

    &::placeholder
      color: #C1C1C1
      font-size: 0.875rem
      -webkit-text-fill-color: initial
      text-shadow: none

  label
    position: absolute
    top: -10px
    left: 2.5rem
    font-size: 0.75rem
    background-color: white
    padding-left: 0.25rem
    padding-right: 0.25rem
    transition-property: color
    transition-duration: 0.2s
    transition-timing-function: linear

  &:focus
    outline: none
</style>

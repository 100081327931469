var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "custom-input",
      class: {
        "custom-input--focused": _vm.focused,
        "custom-input--error": _vm.error
      }
    },
    [
      _c("div", { staticClass: "input-content" }, [
        _vm.value ? _c("label", [_vm._v(_vm._s(_vm.label))]) : _vm._e(),
        _c(
          "div",
          { staticClass: "d-flex align-items-center justify-content-center" },
          [
            _vm._t("left-side"),
            _vm.$attrs.type === undefined || _vm.$attrs.type !== "textarea"
              ? _c(
                  "input",
                  _vm._b(
                    {
                      domProps: { value: _vm.value },
                      on: {
                        focus: function($event) {
                          _vm.focused = true
                        },
                        blur: function($event) {
                          _vm.focused = false
                        },
                        input: function($event) {
                          return _vm.$emit("input", $event.target.value)
                        }
                      }
                    },
                    "input",
                    _vm.$attrs,
                    false
                  )
                )
              : _c(
                  "textarea",
                  _vm._b(
                    {
                      domProps: { value: _vm.value },
                      on: {
                        focus: function($event) {
                          _vm.focused = true
                        },
                        blur: function($event) {
                          _vm.focused = false
                        },
                        input: function($event) {
                          return _vm.$emit("input", $event.target.value)
                        }
                      }
                    },
                    "textarea",
                    _vm.$attrs,
                    false
                  )
                ),
            _vm._t("right-side", null, {
              error: _vm.error,
              focused: _vm.focused
            }),
            _vm.error
              ? _c("strong", [
                  _c("i", { staticClass: "tmlps-icn-alert h4 error-indicator" })
                ])
              : _vm._e()
          ],
          2
        )
      ]),
      _vm.error
        ? _c("div", { staticClass: "error-message" }, [
            _vm._v(_vm._s(_vm.error))
          ])
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }